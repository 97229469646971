import React, { useState } from 'react'
import _ from 'lodash'

import AutorenewRoundedIcon from '@material-ui/icons/AutorenewRounded'
import RotateLeftIcon from '@material-ui/icons/RotateLeft'

import LotteryItem from './LotteryItem'

import { Ammo, Armours, Backpacks, Guns, Helmets, Jokers, Modifiers, Rigs } from './Constants'

import './App.css'

const checkJoker = (setJoker, setTheRerol) => {
  const joker = _.sample(Jokers)
  const checkJokser = joker.name === 'Reroll a card' && true

  setJoker(joker)
  setTheRerol(checkJokser)
}

const checkArmour = (setArmour, setRig) => {
  const armour = _.sample(Armours)
  const rig = _.sample(Rigs)

  if (armour.rig) {
    setArmour(armour)
    setRig({ name: 'none' })
  }

  if (!armour.rig) {
    setArmour(armour)
    setRig(rig)
  }
}

const rerol = (
  type,
  setRolling,
  setArmour,
  setHelmet,
  setBackpack,
  setGun,
  setAmmo,
  setModifier,
  setRig,
  setTheRerol
) => {
  setTheRerol(false)

  switch (type) {
    case 'helmet':
      setHelmet(_.sample(Helmets))
      break
    case 'gun':
      setGun(_.sample(Guns))
      break
    case 'armour':
      checkArmour(setArmour, setRig)
      break
    case 'backpack':
      setBackpack(_.sample(Backpacks))
      break
    case 'modifiers':
      setModifier(_.sample(Modifiers))
      break
    case 'rig':
      setRig(_.sample(Rigs))
      break
    case 'ammo':
      setAmmo(_.sample(Ammo))
      break
    default:
      break
  }
}

const randomizer = (
  setRolling,
  setArmour,
  setHelmet,
  setBackpack,
  setGun,
  setAmmo,
  setModifier,
  setJoker,
  setRig,
  delay,
  setTheRerol
) => {
  // Reset the rerol
  setTheRerol(false)

  // Set al the Lot items
  checkArmour(setArmour, setRig)
  setBackpack(_.sample(Backpacks))
  setHelmet(_.sample(Helmets))
  setAmmo(_.sample(Ammo))
  setModifier(_.sample(Modifiers))
  setGun(_.sample(Guns))

  // Disable the button
  setRolling(true)

  setTimeout(() => {
    setRolling(false)
    checkJoker(setJoker, setTheRerol)
  }, delay * 8)
}

const App = () => {
  const initText = { name: 'Spin to win' }

  // Buttons
  const [rolling, setRolling] = useState(false)
  const [theRerol, setTheRerol] = useState(false)

  // Lottery
  const [armour, setArmour] = useState(initText)
  const [helmet, setHelmet] = useState(initText)
  const [backpack, setBackpack] = useState(initText)
  const [modifier, setModifier] = useState(initText)
  const [ammo, setAmmo] = useState(initText)
  const [rig, setRig] = useState(initText)
  const [gun, setGun] = useState(initText)
  const [joker, setJoker] = useState(initText)

  // Constants
  const size = 200
  const delay = 1200

  const resetAll = () => {
    setArmour(initText)
    setHelmet(initText)
    setBackpack(initText)
    setModifier(initText)
    setAmmo(initText)
    setRig(initText)
    setGun(initText)
    setJoker(initText)
  }

  const checkifallinit = () => {
    if (
      armour.name === 'Spin to win' &&
      helmet.name === 'Spin to win' &&
      backpack.name === 'Spin to win' &&
      modifier.name === 'Spin to win' &&
      ammo.name === 'Spin to win' &&
      rig.name === 'Spin to win' &&
      gun.name === 'Spin to win' &&
      joker.name === 'Spin to win'
    ) {
      return true
    }
    return false
  }

  const checkJoker = () => {
    const joker = _.sample(Jokers)
    const checkJokerActive = joker.name === 'Reroll a card' && theRerol

    return checkJokerActive
  }

  // list
  const items = [
    { name: 'Gun', type: 'gun', content: gun, time: 0 },
    { name: 'Armour', type: 'armour', content: armour, time: 1 },
    { name: 'Helmet', type: 'helmet', content: helmet, time: 2 },
    { name: 'Backpack', type: 'backpack', content: backpack, time: 3 },
    { name: 'Gun modifiers', type: 'modifiers', content: modifier, time: 4 },
    { name: 'Ammo', type: 'ammo', content: ammo, time: 5 },
    { name: 'Rig', type: 'rig', content: rig, time: 6 },
    { name: 'Joker', type: 'joker', content: joker, time: 7 },
  ]

  return (
    <div className="App">
      <div className="Button">
        <button
          className="button"
          onClick={() => {
            randomizer(
              setRolling,
              setArmour,
              setHelmet,
              setBackpack,
              setGun,
              setAmmo,
              setModifier,
              setJoker,
              setRig,
              delay,
              setTheRerol
            )
          }}
          type="button"
          disabled={rolling || checkJoker()}
        >
          {rolling ? (
            <>
              <AutorenewRoundedIcon fontSize="small" /> Randomizing...
            </>
          ) : (
            'Randomize loadout'
          )}
        </button>
        {!rolling && !checkifallinit() && (
          <button style={{ marginLeft: 10 }} disabled={rolling || checkJoker()} onClick={resetAll}>
            Reset
          </button>
        )}
      </div>

      <div className="lottery">
        {_.chunk(items, 4).map((chunk, chunkkIdx) => (
          <div className="lottery-row" key={chunkkIdx}>
            {chunk.map((i, itemIdx) => {
              return (
                <LotteryItem
                  delay={delay * i.time}
                  type={i.type}
                  title={i.name}
                  contents={i.content}
                  randomize={rolling}
                  rerolable={theRerol}
                  onRerol={type =>
                    rerol(
                      type,
                      setRolling,
                      setArmour,
                      setHelmet,
                      setBackpack,
                      setGun,
                      setAmmo,
                      setModifier,
                      setRig,
                      setTheRerol
                    )
                  }
                  size={size}
                  key={itemIdx}
                />
              )
            })}
          </div>
        ))}
      </div>
    </div>
  )
}

App.propTypes = {}

export default App
