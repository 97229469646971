export const Armours = [
  { name: 'Module-3M bodyarmor', rig: false },
  { name: 'PACA Soft Armor', rig: false },
  { name: '6B2 armor (flora)', rig: false },
  { name: 'MF-UNTAR armor vest', rig: false },
  { name: 'Zhuk-3 Press armor', rig: false },
  { name: '6B23-1 armor (digital flora pattern)', rig: false },
  { name: 'BNTI Kirasa-N armor', rig: false },
  { name: 'Highcom Trooper TFO armor (multicam)', rig: false },
  { name: '6B13 assault armor', rig: false },
  { name: '6B23-2 armor (mountain flora pattern)', rig: false },
  { name: 'BNTI Korund-VM armor', rig: false },
  { name: 'FORT Redut-M body armor', rig: false },
  { name: '6B13 M assault armor (tan)', rig: false },
  { name: 'IOTV Gen4 armor (high mobility kit)', rig: false },
  { name: 'BNTI Gzhel-K armor', rig: false },
  { name: 'FORT Defender-2 body armor', rig: false },
  { name: 'IOTV Gen4 armor (assault kit)', rig: false },
  { name: 'IOTV Gen4 armor (full protection)', rig: false },
  { name: 'FORT Redut-T5 body armor', rig: false },
  { name: '5.11 Hexgrid plate carrier', rig: false },
  { name: 'LBT 6094A Slick Plate Carrier', rig: false },
  { name: 'Zhuk-6a heavy armor', rig: false },
  { name: '6B43 Zabralo-Sh 6A Armor', rig: false },
  { name: '6B5-16 Zh -86 Uley armored rig', rig: true },
  { name: '6B3TM-01M armored rig', rig: true },
  { name: '6B5-15 Zh -86 Uley armored rig', rig: true },
  { name: 'ANA Tactical M2 armored rig', rig: true },
  { name: 'ANA Tactical M1 armored rig', rig: true },
  { name: 'Crye Precision AVS platecarrier', rig: true },
  { name: 'Ars Arma A18 Skanda plate carrier', rig: true },
  { name: 'Wartech TV-110 plate carrier', rig: true },
  { name: '5.11 Tactec plate carrier', rig: true },
  { name: 'Ars Arma CPC MOD.2 plate carrier', rig: true },
  { name: 'Players choice' },
]

export const Rigs = [
  { name: 'Scav Vest' },
  { name: 'Security vest' },
  { name: 'DIY IDEA chest rig' },
  { name: 'Spiritus Systems Bank Robber Chest Rig' },
  { name: 'SOE Micro Rig' },
  { name: 'Wartech gear rig (TV-109, TV-106)' },
  { name: 'CSA chest rig' },
  { name: 'UMTBS 6sh112 Scout-Sniper' },
  { name: 'Splav Tarzan M22 Rig' },
  { name: 'Haley Strategic D3CRX Chest Harness' },
  { name: 'Triton M43-A Chest Harness' },
  { name: 'Blackhawk! Commando Chest Harness' },
  { name: 'Direct Action Thunderbolt compact chest rig' },
  { name: 'Gear Craft GC-BSS-MK1 rig' },
  { name: "Umka М33-SET1 hunter's vest" },
  { name: 'LBT-1961A Load Bearing Chest Vest' },
  { name: 'BlackRock chest rig' },
  { name: 'Wartech MK3 chest rig (TV-104)' },
  { name: 'ANA Tactical Alpha chest rig' },
  { name: 'Azimut SS Jhuk Chest Harness' },
  { name: 'Velocity Systems Multi-Purpose Patrol Vest' },
  { name: 'Belt-A + Belt-B gear rig' },
]

export const Helmets = [
  { name: 'Armasight NVG mask' },
  { name: 'Wilcox Skull Lock head mount' },
  { name: 'Tac-Kek Fast MT Helmet (non-ballistic replica)' },
  { name: 'Soft tank crew helmet TSH-4M-L' },
  { name: 'Kolpak-1S riot helmet' },
  { name: 'SHPM Firefighters helmet' },
  { name: 'PSH-97 "Djeta" helmet' },
  { name: 'UNTAR helmet' },
  { name: '6B47 Ratnik-BSh Helmet' },
  { name: 'LZSh light helmet' },
  { name: 'SSh-68 helmet (1968 steel helmet)' },
  { name: 'Kiver-M Helmet' },
  { name: 'DEVTAC Ronin ballistic helmet' },
  { name: 'SSSh-95 Sfera-S (Sphere-S)' },
  { name: 'MSA ACH TC-2001 MICH Series Helmet' },
  { name: 'MSA ACH TC-2002 MICH Series Helmet' },
  { name: 'MSA Gallet TC 800 High Cut combat helmet' },
  { name: 'Highcom Striker ACHHC IIIA helmet' },
  { name: 'ZSh-1-2M helmet' },
  { name: 'Highcom Striker ULACH IIIA helmet' },
  { name: 'Diamond Age Bastion Helmet' },
  { name: 'Ops-Core Fast MT SUPER HIGH CUT Helmet' },
  { name: 'Crye Precision Airframe Tan' },
  { name: 'Team Wendy EXFIL Ballistic Helmet' },
  { name: 'Galvion Caiman Ballistic Helmet' },
  { name: 'BNTI LSHZ-2DTM Helmet' },
  { name: 'Maska 1Sch helmet' },
  { name: 'Rys-T helmet' },
  { name: 'Kinda cowboy hat' },
  { name: 'Ushanka ear-flap cap' },
  { name: 'Miltec panama hat' },
  { name: '"Door Kicker" Boonie hat' },
  { name: 'Beanie' },
  { name: 'Kotton beanie' },
  { name: 'Army cap' },
  { name: 'Baseball cap' },
  { name: 'Police cap' },
  { name: 'Tactical fleece hat' },
  { name: 'UX PRO Beanie' },
  { name: 'USEC baseball cap' },
  { name: 'BEAR baseball cap' },
  { name: 'USEC baseball cap black' },
  { name: 'BEAR baseball cap black' },
  { name: 'EmerCom cap' },
  { name: 'Pompon hat' },
  { name: 'Bandana' },
  { name: 'Ski hat with holes for eyes' },
  { name: 'Olive beret' },
  { name: 'Black beret' },
  { name: 'Blue beret' },
  { name: 'None' },
]

export const Backpacks = [
  { name: '6SH118 raid backpack' },
  { name: 'LBT-2670 Slim Field Med Pack' },
  { name: 'Mystery Ranch Blackjack 50 backpack (multicam)' },
  { name: 'Eberlestock F4 Terminator load bearing backpack (tiger stripe)	' },
  { name: 'SSO Attack 2 raid backpack' },
  { name: 'Pilgrim tourist backpack' },
  { name: "3V G Paratus 3-Day Operator's Tactical Backpack" },
  { name: 'Eberlestock G2 Gunslinger II backpack (dry earth)' },
  { name: 'Oakley Mechanism heavy duty backpack (black)' },
  { name: 'Camelbak Tri-Zip Backpack' },
  { name: 'Ana tactical Beta 2 battle backpack' },
  { name: 'Eberlestock F5 Switchblade backpack (dry earth)' },
  { name: 'Hazard4 Takedown sling backpack' },
  { name: 'Wartech Berkut VV-102 backpack' },
  { name: 'LBT-8005A Day Pack backpack' },
  { name: 'Scav Backpack' },
  { name: 'Flyye MBSS Backpack' },
  { name: 'Sanitar bag' },
  { name: 'Duffle bag' },
  { name: 'LK 3F Transfer tourist backpack' },
  { name: 'Transformer Bag' },
  { name: 'VKBO army bag' },
  { name: 'Tactical sling bag' },
  { name: 'None' },
  { name: 'Players choice' },
]

export const Guns = [
  { name: 'ADAR 2-15' },
  { name: 'AK-101' },
  { name: 'AK-102' },
  { name: 'AK-103' },
  { name: 'AK-104' },
  { name: 'AK-105' },
  { name: 'AK-74' },
  { name: 'AK-74M' },
  { name: 'AK-74N' },
  { name: 'AKM' },
  { name: 'AKMN' },
  { name: 'AKMS' },
  { name: 'AKMSN' },
  { name: 'AKS-74' },
  { name: 'AKS-74N' },
  { name: 'AKS-74U' },
  { name: 'AKS-74UB' },
  { name: 'AKS-74UN' },
  { name: 'ASh-12' },
  { name: 'AS VAL' },
  { name: 'DT MDR 5.56x45' },
  { name: 'DT MDR .308' },
  { name: 'HK 416A5' },
  { name: 'Kel-Tec RFB 7.62x51' },
  { name: 'M4A1' },
  { name: 'MCX' },
  { name: 'SA-58' },
  { name: 'TX-15 DML' },
  { name: 'Vepr AKM/VPO-209' },
  { name: 'Vepr KM/VPO-136' },
  { name: 'OP-SKS' },
  { name: 'SKS' },
  { name: 'Vepr Hunter/VPO-101' },
  { name: 'RPK-16' },
  { name: 'MP5' },
  { name: 'MP5K-N' },
  { name: 'MP7A1' },
  { name: 'MP7A2' },
  { name: 'MP9' },
  { name: 'MP9-N' },
  { name: 'MPX' },
  { name: 'P90' },
  { name: 'PP-19-01 Vityaz-SN' },
  { name: 'PP-9 Klin' },
  { name: 'PP-91 Kedr' },
  { name: 'PP-91-01 Kedr-B' },
  { name: 'PPSH-41' },
  { name: 'Saiga-9' },
  { name: 'STM-9' },
  { name: 'UMP 45' },
  { name: 'Vector .45' },
  { name: 'Vector 9x19' },
  { name: '590A1' },
  { name: 'M870' },
  { name: 'MP-133' },
  { name: 'MP-153' },
  { name: 'Saiga-12' },
  { name: 'TOZ-106' },
  { name: 'KS-23M' },
  { name: 'M1A' },
  { name: 'Mk-18' },
  { name: 'RSASS' },
  { name: 'SR-25' },
  { name: 'SVDS' },
  { name: 'VSS Vintorez' },
  { name: 'DVL-10' },
  { name: 'M700' },
  { name: 'Mosin' },
  { name: 'Mosin Inf.' },
  { name: 'SV-98' },
  { name: 'T-5000' },
  { name: 'VPO-215' },
  { name: 'APB' },
  { name: 'APS' },
  { name: 'FN 5-7' },
  { name: 'GLOCK17' },
  { name: 'GLOCK18C' },
  { name: 'M1911A1' },
  { name: 'M45A1' },
  { name: 'M9A3' },
  { name: 'MP-443 Grach' },
  { name: 'P226R' },
  { name: 'PB pistol' },
  { name: 'PL-15' },
  { name: 'PM (t) pistol' },
  { name: 'PM pistol' },
  { name: 'SR-1MP Gyurza' },
  { name: 'TT pistol' },
  { name: 'TT pistol (gold)' },
]

export const Modifiers = [
  { name: 'Trader level 1' },
  { name: 'Trader level 2' },
  { name: 'Trader level 3' },
  { name: 'Trader level 4' },
  { name: 'No modifications' },
  { name: 'No scope' },
]

export const Ammo = [
  { name: 'Trader level 1' },
  { name: 'Trader level 2' },
  { name: 'Trader level 3' },
  { name: 'Trader level 4' },
  { name: 'Cheapest ammo' },
  { name: 'Only one mag' },
  { name: 'Only two mags' },
  { name: 'Largests drum mag available' },
  { name: 'Smallest mag available' },
  { name: 'Loose ammo and empty mags' },
  { name: 'Loose ammo and one mag' },
]

export const Jokers = [
  { name: 'No running' },
  { name: '3 laserlights' },
  { name: 'Always flashlight on' },
  { name: 'Nighttime' },
  { name: 'Only one tap' },
  { name: 'Fill backpack with milk' },
  { name: 'Reroll a card' },
  { name: 'No grenades' },
  { name: 'Take car batery with you' },
  { name: 'No Meds' },
  { name: 'Meds therapist 1' },
  { name: 'No Surv12 or CMS' },
  { name: 'Eat whole jar of DevilDog mayo at start of the raid, no propital' },
  { name: 'Drink Bottle of vodka Tarkovskaya at the start of the raid' },
  { name: 'No reserve ammo' },
  { name: 'No keys' },
  { name: 'No stims' },
  { name: 'Use thermal scope' },
  { name: 'None' },
]
