import React from 'react'
import PropTypes from 'prop-types'

import anime from 'animejs'
import styled from 'styled-components'

import AutorenewRoundedIcon from '@material-ui/icons/AutorenewRounded'

const LotteryItemd = styled.div`
  background: rgb(101, 3, 100);
  height: ${props => `${props.size}px` || '250px'};
  width: ${props => `${props.size}px` || '250px'};
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(255, 255, 255, 0.1);
`

const LotteryBlock = styled.div`
  font-size: 22px;
  display: inline-block;
  margin-bottom: 10px;
  margin-right: 20px;
  height: ${props => `${props.size}px` || '250px'};
  width: ${props => `${props.size}px` || '250px'};
  overflow: hidden;
`

const LotterItemLot = styled.div`
  display: flex;
  background: purple;
  color: white;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 25px;
  top: ${props => `-${props.size}px` || '-250px'};
  position: relative;
  height: ${props => `${props.size}px` || '250px'};
  width: ${props => `${props.size}px` || '250px'};
  background-position: center;
  background-repeat: no-repeat;
`

const Label = styled.div`
  position: absolute;
  color: white;
  margin-top: -35px;
  text-align: center;
  width: ${props => `${props.size}px` || '250px'};
`

const shuffle = (delay, type, size) => {
  const time = 900
  const basicTimeline = anime.timeline({
    easing: 'easeOutQuad',
    suspendWhenDocumentHidden: false,
  })

  const givenSize = size

  basicTimeline
    .add({
      targets: `.lottery-item-lot.${type} p, .lottery-item-lot.${type}`,
      opacity: 0,
      duration: 0,
    })
    .add({
      delay,
      targets: `.lottery-item-lot.${type}`,
      keyframes: [{ translateY: [0, givenSize] }],
      duration: time / 7,
    })
    .add({
      targets: `.lottery-item.${type}`,
      keyframes: [
        { translateY: [0, givenSize] },
        { translateY: [-givenSize, givenSize] },
        { translateY: [-givenSize, givenSize] },
        { translateY: [-givenSize, givenSize] },
        { translateY: [-givenSize, givenSize] },
        { translateY: [-givenSize, givenSize] },
      ],
      easing: 'linear',
      duration: time,
    })
    .add({
      targets: `.lottery-item-lot.${type}`,
      opacity: 1,
      keyframes: [{ translateY: [-givenSize + -30, 0], easing: 'easeInOutElastic' }],
      duration: 500,
    })
    .add({
      targets: `.lottery-item-lot.${type} p, .lottery-item-lot.${type}`,
      opacity: 1,
      duration: 7 / 7,
    })
}

const LotteryItem = ({ contents, randomize, delay, type, title, size, onRerol, rerolable }) => {
  if (randomize) {
    shuffle(delay, type, size)
  }

  return (
    <LotteryBlock className={`lottery-block ${rerolable === true && type !== 'joker' && 'rolable'}`} size={size}>
      <Label className="label" size={size}>
        {title}
      </Label>

      <LotteryItemd className={`lottery-item ${type} `} size={size}>
        <AutorenewRoundedIcon fontSize="large" />
      </LotteryItemd>

      <LotterItemLot
        className={`lottery-item-lot ${type} ${rerolable === true && type === 'joker' && 'rolable'}`}
        style={{ backgroundImage: `url(${contents.image})` }}
        size={size}
        onClick={() => {
          // eslint-disable-next-line
          type !== 'joker' && rerolable === true && shuffle(0, type, size)
          // eslint-disable-next-line
          type !== 'joker' && rerolable === true && onRerol(type)
        }}
      >
        <p>{contents.image ? '' : contents.name}</p>
      </LotterItemLot>
    </LotteryBlock>
  )
}

LotteryItem.propTypes = {
  contents: PropTypes.shape({
    name: PropTypes.string.isRequired,
    image: PropTypes.string,
  }).isRequired,

  randomize: PropTypes.bool.isRequired,
  rerolable: PropTypes.bool.isRequired,

  onRerol: PropTypes.func.isRequired,

  delay: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
}

export default LotteryItem
